import cx from 'classnames';
import { useDidMount } from 'lib/hooks/useDidMount';
import {
  JSXElementConstructor,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';

import {
  TooltipContent,
  TooltipRoot,
  TooltipTrigger
} from 'components/shared/Tooltip';
import ArrowCursor from 'icons/TooltipArrowIcon';

// Map Tippy placement types to Radix UI side and align
type PlacementType =
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'left'
  | 'left-start'
  | 'left-end';

// Convert Tippy placement to Radix UI side and align
const placementToSideAlign = (
  placement: PlacementType
): {
  side: 'top' | 'right' | 'bottom' | 'left';
  align: 'start' | 'center' | 'end';
} => {
  const [side, align] = placement.split('-') as [any, any];
  return {
    side: side || 'top',
    align: align || 'center'
  };
};

type Props = {
  isVisible: boolean;
  onHide: () => void;
  renderedTooltip: React.ReactNode;
  children: ReactElement<unknown, string | JSXElementConstructor<unknown>>;
  context?: string;
  placement?: PlacementType;
  arrowClassName?: string;
  appendTo?: Element | 'parent' | ((ref: Element) => Element) | undefined;
  offset?: [number, number];
};

const TOOLTIP_WIDTH = 403;

const ARROW_SIDE = {
  LEFT: 'LEFT',
  RIGHT: 'RIGHT'
};

function ButtonTooltip({
  isVisible,
  onHide,
  children,
  renderedTooltip,
  context = '',
  placement = 'top-start',
  arrowClassName,
  appendTo = () => document.body,
  offset = [0, 0]
}: PropsWithChildren<Props>): React.ReactNode {
  const hasMounted = useDidMount();
  const buttonRef = useRef<HTMLDivElement>(null);
  const [tooltipPosition, setTooltipPosition] = useState<string>();
  const { side, align } = placementToSideAlign(placement);

  // Auto-close after 5 seconds
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (isVisible) {
      timeoutId = setTimeout(() => {
        onHide();
      }, 5000);
    }
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [isVisible, onHide]);

  const calculatePosition = useCallback(() => {
    const buttonElement = buttonRef.current;
    if (!buttonElement) return;

    const buttonRect = buttonElement.getBoundingClientRect();
    const spaceLeft = window.innerWidth - buttonRect.left;

    if (spaceLeft > TOOLTIP_WIDTH) {
      setTooltipPosition(ARROW_SIDE.LEFT);
    } else {
      setTooltipPosition(ARROW_SIDE.RIGHT);
    }
  }, [buttonRef]);

  useEffect(() => {
    window.addEventListener('resize', calculatePosition);

    return () => {
      window.removeEventListener('resize', calculatePosition);
    };
  }, [calculatePosition]);

  useEffect(() => {
    if (hasMounted && buttonRef?.current) {
      calculatePosition();
    }
  }, [buttonRef, hasMounted, calculatePosition]);

  const arrowClasses = cx('hidden h-7 w-7 sm-tui:block', arrowClassName, {
    'ml-8 md-tui:ml-7': tooltipPosition === ARROW_SIDE.LEFT,
    'ml-72 sm-tui:ml-72': tooltipPosition === ARROW_SIDE.RIGHT
  });

  return (
    <TooltipRoot open={isVisible} onOpenChange={open => !open && onHide()}>
      <TooltipTrigger asChild>
        <div ref={buttonRef}>{children}</div>
      </TooltipTrigger>
      <TooltipContent
        side={side}
        align={align}
        sideOffset={offset[1]}
        className="border-0 bg-transparent p-0 shadow-none"
        data-testid={`${context}Tooltip_TESTID`}
      >
        <div className="mb-1 md-tui:mb-[-10px]">
          {renderedTooltip}
          <ArrowCursor variant="default" className={arrowClasses} />
        </div>
      </TooltipContent>
    </TooltipRoot>
  );
}

export default ButtonTooltip;
