import type { JSX } from 'react';

type WhatsappIconProps = {
  className?: string;
};

const WhatsappIcon = (props: WhatsappIconProps): JSX.Element => {
  const { className } = props;
  return (
    <svg
      className={className || ''}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 40C31.0457 40 40 31.0457 40 20C40 8.95431 31.0457 0 20 0C8.95431 0 0 8.95431 0 20C0 31.0457 8.95431 40 20 40Z"
        fill="#25D366"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.6596 31.1273H20.6546C18.6642 31.1266 16.7085 30.6272 14.9714 29.6797L8.66699 31.3335L10.3542 25.1709C9.31344 23.3673 8.76581 21.3214 8.7667 19.2254C8.76931 12.6682 14.1043 7.3335 20.6594 7.3335C23.8409 7.33487 26.8268 8.57338 29.0722 10.8213C31.3175 13.0692 32.5534 16.0571 32.5521 19.2347C32.5495 25.7904 27.2166 31.1246 20.6596 31.1273V31.1273ZM15.263 27.5267L15.6239 27.7409C17.1416 28.6415 18.8813 29.1181 20.655 29.1188H20.659C26.1071 29.1188 30.5414 24.6844 30.5436 19.234C30.5446 16.5928 29.5174 14.1094 27.6512 12.241C25.7849 10.3725 23.3031 9.34308 20.6629 9.34216C15.2105 9.34216 10.7763 13.7761 10.7741 19.226C10.7733 21.0938 11.2959 22.9127 12.2854 24.4865L12.5204 24.8605L11.5218 28.5081L15.263 27.5267ZM26.6509 22.0628C26.5767 21.9388 26.3786 21.8644 26.0814 21.7156C25.7843 21.5669 24.3233 20.8481 24.0509 20.7489C23.7786 20.6497 23.5804 20.6002 23.3823 20.8976C23.1842 21.195 22.6148 21.8644 22.4414 22.0628C22.2681 22.261 22.0947 22.2859 21.7977 22.1371C21.5005 21.9884 20.543 21.6746 19.408 20.6622C18.5246 19.8742 17.9282 18.9012 17.7549 18.6037C17.5815 18.3062 17.7364 18.1454 17.8852 17.9973C18.0188 17.8641 18.1823 17.6501 18.3309 17.4766C18.4795 17.3032 18.529 17.1791 18.628 16.9809C18.7271 16.7825 18.6776 16.6091 18.6033 16.4603C18.529 16.3116 17.9347 14.8489 17.6871 14.2539C17.4459 13.6745 17.2009 13.753 17.0185 13.7438C16.8454 13.7351 16.6471 13.7333 16.449 13.7333C16.2509 13.7333 15.9289 13.8077 15.6566 14.1052C15.3842 14.4027 14.6165 15.1216 14.6165 16.5841C14.6165 18.0468 15.6813 19.4598 15.8299 19.6581C15.9785 19.8565 17.9253 22.8579 20.9062 24.1451C21.6152 24.4512 22.1686 24.6341 22.6002 24.771C23.3121 24.9972 23.9599 24.9653 24.4718 24.8888C25.0428 24.8035 26.23 24.17 26.4777 23.4759C26.7252 22.7816 26.7252 22.1867 26.6509 22.0628V22.0628Z"
        fill="#FDFDFD"
      />
    </svg>
  );
};
export default WhatsappIcon;
