import cx from 'classnames';
import { DialogContent, DialogOverlay } from 'components/@tedui/Dialog/Dialog';
import ContentContainer from 'components/content-container';
import Grid from 'components/grid';
import Col from 'components/grid/col';
import { useIsMobile } from 'lib/hooks/useIsBreakpointWidth/useIsMobile';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import CloseIcon from 'icons/CloseIcon';

export enum ModalType {
  Small = 'SMALL',
  Medium = 'MEDIUM',
  Large = 'LARGE'
}

const GRID_COL_SPAN = {
  [ModalType.Small]: {
    sm: 0,
    md: 4,
    xl: 4,
    offsetSm: 0,
    offsetMd: 2,
    offsetXl: 4
  },
  [ModalType.Medium]: {
    sm: 0,
    md: 6,
    xl: 6,
    offsetSm: 0,
    offsetMd: 1,
    offsetXl: 3
  },
  [ModalType.Large]: {
    sm: 4,
    md: 8,
    xl: 8,
    offsetSm: 0,
    offsetMd: 0,
    offsetXl: 2
  }
};

type ModalProps = {
  children: React.ReactNode;
  isOpen: boolean;
  type?: ModalType;
  onDismiss: () => void;
  stickyCloseButton?: boolean;
  noOverflow?: boolean;
  noPadding?: boolean;
  tall?: boolean;
  closeButtonAriaMessage?: string;
  ariaLabel: string;
  maxWidth?: boolean;
  contentWrapperClassName?: string;
  modalClassName?: string;
  overlayClassName?: string;
  showCloseButton?: boolean;
};

function Modal({
  type = ModalType.Large,
  children,
  isOpen,
  onDismiss,
  stickyCloseButton,
  noOverflow,
  noPadding,
  tall,
  closeButtonAriaMessage,
  ariaLabel,
  maxWidth,
  contentWrapperClassName = '',
  modalClassName = '',
  overlayClassName = '',
  showCloseButton = true
}: ModalProps & React.HTMLAttributes<HTMLElement>): React.ReactNode {
  const intl = useIntl();
  const { currentScreenWidth } = useIsMobile();

  const closeButtonDefaultAriaMessage = intl.formatMessage({
    defaultMessage: 'Close'
  });
  const buttonAriaLabel =
    closeButtonAriaMessage || closeButtonDefaultAriaMessage;

  const isSmall = type === ModalType.Small;
  const isMedium = type === ModalType.Medium;
  const isLarge = type === ModalType.Large;

  const shouldUseGrid =
    isSmall || isMedium
      ? currentScreenWidth >= 768
      : currentScreenWidth >= 1280;

  const overlayClasses = cx(
    'fixed inset-0 z-40 flex h-full animate-fadeIn items-end bg-black/20',
    overlayClassName
  );

  const modalClasses = cx(
    'relative h-auto min-h-drawer w-full rounded-t-md bg-white',
    {
      'max-h-1/3-screen xl:max-h-1/2-screen': isSmall,
      'max-h-1/2-screen xl:max-h-3/5-screen': isMedium,
      'no-scrollbar animate-slideInModal overflow-y-auto md:min-h-modal md:animate-none md:rounded-md':
        isSmall || isMedium,
      'xl:min-h-modal xl:rounded-md': isLarge,
      'md:fixed md:bottom-0 md:left-0 md:right-0 md:h-4/5 xl:relative xl:h-3/5 xl:overflow-y-hidden':
        noOverflow && isLarge,
      'no-scrollbar xl:max-h-3/5-screen xl:overflow-y-auto':
        !noOverflow && isLarge,
      'px-5 pt-6 md:pl-12 md:pr-5 md:pt-5': !noPadding,
      'max-w-3xl': isLarge && maxWidth,
      'max-w-xl': isMedium && maxWidth
    },
    modalClassName
  );

  const buttonClasses = cx(
    'z-20 float-right block -translate-y-25 translate-x-25 rounded-full bg-white p-25 text-black hover:text-gray-700',
    {
      'right-5 top-6 mr-5 mt-6 md:top-5 md:mt-5': noPadding,
      'top-0': (isSmall || isMedium) && !noPadding,
      'sticky z-10': stickyCloseButton,
      'top-6 md:top-5': isLarge,
      'xl:top-0': !noPadding && isLarge
    }
  );

  const contentWrapperClasses = cx(
    {
      'md:pr-7': !noPadding
    },
    contentWrapperClassName
  );

  const handleContentClick = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
  }, []);

  const renderModalContent = () => (
    <>
      <div onClick={handleContentClick}>
        {showCloseButton && (
          <button
            type="button"
            className={buttonClasses}
            onClick={onDismiss}
            aria-label={buttonAriaLabel}
          >
            <CloseIcon variant="default" className="h-6 w-6" />
          </button>
        )}
        <div className={contentWrapperClasses}>{children}</div>
      </div>
    </>
  );

  const renderWithGrid = () => (
    <ContentContainer className="h-full">
      <Grid eightColGrid className="h-full">
        <Col
          sm={GRID_COL_SPAN[type].sm}
          md={GRID_COL_SPAN[type].md}
          xl={GRID_COL_SPAN[type].xl}
          offsetSm={GRID_COL_SPAN[type].offsetSm}
          offsetMd={GRID_COL_SPAN[type].offsetMd}
          offsetXl={GRID_COL_SPAN[type].offsetXl}
          className="flex h-screen items-center justify-center"
        >
          <DialogContent
            className={modalClasses}
            aria-label={ariaLabel}
            title={ariaLabel}
            style={tall ? { maxHeight: '90vh' } : undefined}
          >
            {renderModalContent()}
          </DialogContent>
        </Col>
      </Grid>
    </ContentContainer>
  );

  const renderWithoutGrid = () => (
    <DialogContent
      className={modalClasses}
      aria-label={ariaLabel}
      title={ariaLabel}
      style={tall ? { maxHeight: '90vh' } : undefined}
    >
      {renderModalContent()}
    </DialogContent>
  );

  return (
    <DialogOverlay
      className={overlayClasses}
      isOpen={isOpen}
      onDismiss={onDismiss}
    >
      {shouldUseGrid ? renderWithGrid() : renderWithoutGrid()}
    </DialogOverlay>
  );
}

export default Modal;
