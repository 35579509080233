import { useCallback, useState } from 'react';

interface TooltipControls {
  visibleTooltip: string | null;
  hideTooltip: () => void;
  showTooltip: (id: string) => void;
  toggleToolTip: (id: string) => void;
}

function useTooltipControls(singleTooltipMode = false): TooltipControls {
  const [visibleTooltip, setVisibleTooltip] = useState<string | null>(null);

  const hideTooltip = useCallback(() => {
    setVisibleTooltip(null);
  }, []);

  const showTooltip = useCallback(
    (id: string) => {
      if (singleTooltipMode || id !== visibleTooltip) {
        setVisibleTooltip(id);
      }
    },
    [singleTooltipMode, visibleTooltip]
  );

  const toggleToolTip = useCallback((id: string) => {
    setVisibleTooltip(prevState => (prevState === id ? null : id));
  }, []);

  return {
    visibleTooltip,
    hideTooltip,
    showTooltip,
    toggleToolTip
  };
}

export default useTooltipControls;
