import type { JSX } from 'react';

type TooltipArrowIconProps = {
  className?: string;
  variant: 'default' | 'blue';
};

const TooltipArrowIcon = (props: TooltipArrowIconProps): JSX.Element => {
  const { className, variant } = props;
  switch (variant) {
    case 'blue':
      return (
        <svg
          className={className || ''}
          width="27"
          height="18"
          viewBox="0 0 27 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.6102 1.41841C12.6071 0.26726 14.3929 0.267259 15.3898 1.41841L25.3014 12.8634C26.7036 14.4825 25.5535 17 23.4116 17L3.58838 17C1.4465 17 0.296368 14.4825 1.69856 12.8634L11.6102 1.41841Z"
            fill="#00105B"
            stroke="#6E6E71"
          />
        </svg>
      );
    case 'default':
    default:
      return (
        <svg
          className={className || ''}
          width="25"
          height="16"
          viewBox="0 0 25 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.9881 15.2543C11.7857 16.1752 13.2143 16.1752 14.0119 15.2543L23.9235 3.80931C25.0452 2.51402 24.1251 0.5 22.4116 0.5H2.58838C0.874875 0.5 -0.0452271 2.51402 1.07652 3.80931L10.9881 15.2543Z"
            fill="#5786FF"
          />
        </svg>
      );
  }
};
export default TooltipArrowIcon;
