import { client, switchFn } from 'f';

interface ShareOptions {
  message?: string;
  url: string;
}

interface ServiceOptions {
  service: string;
  options: ShareOptions;
}

const serviceOpts = ({ service, options }: ServiceOptions) => {
  const { message, url } = options;

  // Twitter URL was returning 400 from messages that contain a pipe |, in the message. WEB-589
  const encodedTwitterMessage = encodeURI(message);

  return switchFn({
    mail: () => ({
      href: `mailto:?subject=Check out this TED Talk!&body=${message}%0d%0a%0d%0a${url}`,
      ga: 'email',
      utmMedium: 'email',
      utmSource: 'tedcomshare'
    }),
    facebook: () => ({
      href: `//www.facebook.com/sharer/sharer.php?u=${url}`,
      ga: 'facebook',
      utmSource: 'facebook.com',
      utmMedium: 'social'
    }),
    linkedin: () => ({
      href: `//www.linkedin.com/shareArticle?mini=true&url=${url}&title=${message}&source=TED`,
      ga: 'linkedIn',
      height: 500,
      utmSource: 'linkedin.com',
      utmMedium: 'social'
    }),
    messenger: () => ({
      href: `fb-messenger://share?link=${url}`,
      ga: 'fb-messenger',
      utmSource: 'fb-messenger'
    }),
    sms: () => ({
      href: `sms:?&body=${url}`,
      ga: 'sms',
      utmMedium: 'social',
      utmSource: 'sms'
    }),
    twitter: () => ({
      href: `//twitter.com/intent/tweet?url=${url}&via=TEDTalks&text=${encodedTwitterMessage}`,
      ga: 'twitter',
      utmSource: 'twitter.com',
      utmMedium: 'social'
    }),
    url: () => ({
      href: url
    }),
    whatsapp: () => ({
      href: `whatsapp://send?text=${url}`,
      ga: 'whatsapp',
      utmSource: 'whatsapp',
      utmMedium: 'social'
    }),
    default: null
  })(service);
};

const getShareAttributes = async ({
  service,
  options
}: ServiceOptions): Promise<{
  href: string;
  width: number;
  height: number;
}> => {
  const { url } = options;
  const serviceLC = service.toLowerCase();

  // construct base options
  const ogImage = document.querySelector('meta[property="og:image"]');
  const baseOpts = {
    media: ogImage ? ogImage.getAttribute('content') : '',
    height: 450,
    width: 500,
    utmSource: 'tedcomshare',
    utmMedium: 'social',
    utmCampaign: 'tedspread',
    ...options
  };

  // construct url without share params
  const shortUrl = url; // Shortened url passed in directly

  // encode url
  const encode = encodeURIComponent;
  const encodeUrl = serviceLC !== 'messenger' && serviceLC !== 'url';
  const encodedUrl = encodeUrl ? encode(shortUrl) : shortUrl;

  // merge base options and service options
  const opts = {
    ...baseOpts,
    ...serviceOpts({
      service: serviceLC,
      options: {
        ...options,
        url: encodedUrl
      }
    })
  };

  const { href, height, width } = opts;
  return { href, height, width };
};

// Updated function: openShareUrl
const openShareUrl = async ({
  service,
  options
}: ServiceOptions): Promise<void> => {
  const { href, height, width } = await getShareAttributes({
    service,
    options
  });

  // Add matches for url shemes that open iOS/Android apps
  const outputUrlOpensApp = href.match(/whatsapp:|sms:|fb-messenger:|mailto:/);

  // trigger share action
  if (client) {
    if (outputUrlOpensApp) {
      window.location.href = href;
    } else {
      window
        .open(
          href,
          'sharingpopup',
          [
            'scrollbars=1',
            'resizable=1',
            `height=${height}`,
            `width=${width}`
          ].join(',')
        )
        .focus();
    }
  }
};

export { openShareUrl, getShareAttributes };
