import dynamic from 'next/dynamic';
import { PropsWithChildren } from 'react';

import { FormattedMessage } from 'react-intl';

import { SVGIcon } from 'components/@tedui/Icon';
import { BaseButtonType } from 'components/base-button';
import { TalkActionButtonProps } from './TalkActionButton.props';

const Button = dynamic(() => import('components/button'), {
  ssr: false
});

const NewIcon = () => (
  <svg
    width="25"
    height="12"
    viewBox="0 0 25 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="absolute right-0 top-0"
  >
    <rect width="25" height="12" rx="6" fill="#EB0028" />
    <path
      d="M9.36699 3.63477V8H8.65801L6.6043 5.02637H6.56621V8H5.7752V3.63477H6.48418L8.53789 6.6084H8.57598V3.63477H9.36699ZM10.2998 8V3.63477H13.1416V4.29688H11.0908V5.46875H12.9893V6.12207H11.0908V7.33789H13.1533V8H10.2998ZM14.9475 8L13.717 3.63477H14.5666L15.3547 6.84277H15.3928L16.2336 3.63477H17.01L17.8449 6.8457H17.8889L18.6711 3.63477H19.5266L18.2932 8H17.5109L16.6379 4.94141H16.6027L15.7326 8H14.9475Z"
      fill="white"
    />
  </svg>
);

const TalkActionButton = ({
  ref,
  onClick,
  iconName,
  label,
  newIcon,
  disabled = false,
  testId,
  children
}: PropsWithChildren<TalkActionButtonProps> & {
  ref?: React.RefObject<HTMLButtonElement | null>;
}) => {
  return (
    <Button
      data-testid={testId}
      {...(ref && { ref })}
      disabled={disabled}
      onClick={onClick}
      variant={BaseButtonType.GhostLinkMuted}
      small
      className="relative bg-gray-300 text-sm font-medium text-black hover:z-0 md:bg-transparent md:hover:bg-gray-300"
    >
      <SVGIcon
        className="mr-3 h-4 w-4 flex-shrink-0 origin-left scale-125 transform"
        iconName={iconName}
      />
      <FormattedMessage
        defaultMessage="{label}"
        values={{
          label
        }}
      />
      {children}
      {newIcon && <NewIcon />}
    </Button>
  );
};

export default TalkActionButton;
